import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'

function HeartIcons() {
  return (
    <div className="container-icon">
      <svg
        id="Flat"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        height="40"
        width="40"
        className="operator"
      >
        <path d="M95.97217,72.96875,34.0625,128l61.90967,55.03125a11.99975,11.99975,0,1,1-15.94434,17.9375l-72-64a11.99925,11.99925,0,0,1,0-17.9375l72-64a11.99975,11.99975,0,1,1,15.94434,17.9375Zm152,46.0625-72-64a11.99975,11.99975,0,1,0-15.94434,17.9375L221.9375,128l-61.90967,55.03125a11.99975,11.99975,0,1,0,15.94434,17.9375l72-64a11.99925,11.99925,0,0,0,0-17.9375Z"></path>
      </svg>
      <svg
        id="Flat"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        height="150"
        width="150"
        className="heart"
      >
        <path d="M128.00586,220a3.993,3.993,0,0,1-1.9541-.51,312.79378,312.79378,0,0,1-50.72168-37.01685C41.27344,151.82263,24.00586,121.38306,24.00586,92a56.013,56.013,0,0,1,104-28.87823A56.013,56.013,0,0,1,232.00586,92c0,29.38306-17.26758,59.82263-51.32422,90.47314A312.79378,312.79378,0,0,1,129.96,219.49,3.993,3.993,0,0,1,128.00586,220Zm-48-176a48.054,48.054,0,0,0-48,48c0,59.701,82.17578,111.14148,96,119.36853,13.82422-8.227,96-59.66754,96-119.36853a48.00892,48.00892,0,0,0-92.30957-18.49268,3.99954,3.99954,0,0,1-7.38086,0A47.90343,47.90343,0,0,0,80.00586,44Z"></path>
      </svg>
      <svg
        id="Flat"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        height="40"
        width="40"
        className="operators"
      >
        <path d="M71.68213,97.21875,34.74463,128l36.9375,30.78125a12,12,0,0,1-15.36426,18.4375l-48-40a12.00005,12.00005,0,0,1,0-18.4375l48-40a12,12,0,1,1,15.36426,18.4375Zm176,21.5625-48-40a12,12,0,1,0-15.36426,18.4375L221.25537,128l-36.9375,30.78125a12,12,0,0,0,15.36426,18.4375l48-40a12.00005,12.00005,0,0,0,0-18.4375ZM164.10107,28.72266a12.0021,12.0021,0,0,0-15.37841,7.17675l-64,176a11.99972,11.99972,0,1,0,22.55468,8.20118l64-176A11.99923,11.99923,0,0,0,164.10107,28.72266Z"></path>
      </svg>
    </div>
  )
}

export default HeartIcons;
