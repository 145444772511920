import React from 'react';
import './index.scss';


const Project = () => {
return(
    <div className="page-container">
    <div className="projects-page">
        <h1 className='title-projects'>My projects</h1>
        </div>
    </div>
    )
}


export default Project;


